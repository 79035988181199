import React from "react";

interface InputProps {
  label: string;
  name: string;
  id: string;
  type: string;
  placeholder?: string;
  className?: string;
  error?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { label, name, id, type, placeholder, className, error, value, onChange },
    ref
  ) => {
    return (
      <div className={`w-full ${className}`}>
        <div className="relative">
          <input
            id={id}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            placeholder=" "
            ref={ref}
            className="bg-buckaroo-gray-50 p-2 peer block w-full text-gray-700 border border-gray-300 hover:border-gray-400 rounded-full shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 transition duration-300 ease-in-out"
          />
          <label
            htmlFor={id}
            className="absolute left-3 -top-2.5 rounded-md bg-buckaroo-gray-50 px-1 text-gray-500 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-2.5 peer-focus:text-gray-600 peer-focus:text-sm pointer-events-none">
            {label}
          </label>
        </div>
        {error && (
          <div className="text-xs text-red-500 text-right">{error}</div>
        )}
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
