import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Select, { StylesConfig } from "react-select";
import { ArrowDown } from "../../icons/ArrowDown";

interface LanguageOption {
  value: string;
  label: string;
}

function Navbar() {
  const { i18n } = useTranslation();

  function useQueryParameters() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(Array.from(searchParams.entries()));
    return params;
  }
  const queryParams = useQueryParameters();
  const isPartnerYellow =
    queryParams.partner ===
    "eb653c06e00ff430289f6ae7b3f899b60f2b0e02f2f082f646ce9f2c99b212ba";

  const navigate = useNavigate();
  const location = useLocation();

  const languageOptions: LanguageOption[] = [
    { value: "de", label: "DE" },
    { value: "en", label: "EN" },
    { value: "fr", label: "FR" },
    { value: "nl", label: "NL" },
  ];

  const navbarStyles: StylesConfig<LanguageOption> = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "inherit",
      border: "none",
      borderRadius: "9999px",
      color: "#FFFFFF",
      padding: "2px 12px",
      display: "flex",
      width: "100px",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      boxShadow: state.isFocused ? "0 0 0 1px #FFFFFF" : "none",
      transition: "none",
      "&:active": {
        backgroundColor: "inherit",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#B7ED44"
        : state.isFocused
        ? "#CFF355"
        : "#F4F0EC",
      color: state.isSelected ? "#163255" : "#000000",
      padding: "4px 8px",
      borderRadius: "12px",
      margin: "4px 0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      transition: "none",
      "&:active": {
        backgroundColor: "#CFF355",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#FFFFFF",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      transition: "none",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#F4F0EC",
      borderRadius: "12px",
      marginTop: "8px",
      padding: "0.2rem",
      position: "absolute",
      zIndex: 1050,
      width: "90px",
    }),
  };

  const changeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const lang = e.target.value;
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.set("lang", lang);
    navigate(`${location.pathname}?${urlSearchParams}`);
    i18n.changeLanguage(lang);
  };

  return (
    <nav className="bg-secondary-500 flex justify-between items-center h-[70px]">
      <div className="flex items-center w-full md:w-1/2 h-1/3">
        {isPartnerYellow && (
          <img
            src={require("../../images/AA.png")}
            alt="AA Logo"
            className="md:ml-24 -mr-3 w-[100px] h-[25px] object-contain"
          />
        )}
        <div
          className={`bg-contain bg-no-repeat ${
            !isPartnerYellow ? "xl:ml-40" : "xl:10"
          }`}
          style={{
            width: "100%",
            height: "100%",
            backgroundImage: `url(${require("../../images/png/Buckaroo_logo_green.png")})`,
          }}></div>
      </div>
      <div className="relative w-48 mr-1 xl:mr-10 ml-auto">
        <Select
          options={languageOptions}
          styles={navbarStyles}
          onChange={(option) => {
            const selectedOption = option as LanguageOption;
            const event = { target: { value: selectedOption.value } };
            changeLanguage(event as React.ChangeEvent<HTMLSelectElement>);
          }}
          value={languageOptions.find((opt) => opt.value === i18n.language)}
          placeholder="Select Language"
          isSearchable={false}
          isMulti={false}
          components={{
            DropdownIndicator: () => (
              <div className="flex items-center px-2">
                <ArrowDown className="w-5 h-5" />
              </div>
            ),
            IndicatorSeparator: () => null,
          }}
        />
      </div>
    </nav>
  );
}

export default Navbar;
