import React, { useContext } from "react";
import { PosContext } from "../../context/PosContext";
import { IAccessory } from "../../interfaces/IAccessory";
import AccessoryCard from "../../components/pos/cards/AccessoryCard";
import useCustomNavigate from "../../components/pos/hooks/useCustomNavigate";
import { t } from "i18next";
import Button from "../../components/pos/buttons/Button";
import BackButton from "../../components/pos/ui/BackButton";
import CancelIcon from "../../icons/CancelIcon";

const Accessories: React.FC = () => {
  const {
    updateOrder,
    accessories,
    updateSteps,
    orders,
    activeOrderId,
    updateAggregatedAccessories,
  } = useContext(PosContext);

  const navigate = useCustomNavigate();
  const activeOrder = orders.find((order) => order.orderId === activeOrderId);

  const handleSelectAccessory = (
    selectedAccessory: IAccessory,
    quantity: number
  ) => {
    const currentSelectedAccessories = activeOrder?.selectedAccessories || [];

    const existingAccessoryIndex = currentSelectedAccessories.findIndex(
      (item) => item.accessory.InvoiceCode === selectedAccessory.InvoiceCode
    );

    if (existingAccessoryIndex !== -1) {
      currentSelectedAccessories[existingAccessoryIndex].quantity = quantity;
    } else {
      currentSelectedAccessories.push({
        accessory: selectedAccessory,
        quantity,
      });
    }

    updateOrder({ selectedAccessories: currentSelectedAccessories });
    updateAggregatedAccessories(currentSelectedAccessories);
  };

  const handleContinue = () => {
    updateSteps({ accessories: true });
    navigate("/pos/location");
  };

  const handleBack = () => {
    navigate("/pos/bundle");
  };

  const filteredAccessories = accessories.filter((accessory) =>
    accessory.Compatibilities.includes(
      activeOrder?.selectedTerminal?.ProductName ?? ""
    )
  );

  const emptyMessage = (
    <div className="text-center py-10">
      <div className="flex items-center justify-center">
        <CancelIcon className="w-10 h-10 text-primary-500" />
        <p className="text-primary-700 ml-5">
          {t("pos.accessories.noAccessories")}
        </p>
      </div>
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8 pt-4 bg-white w-full md:max-w-3xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl">
      <BackButton label={t("pos.buttons.back.name")} onClick={handleBack} />
      <h1 className="text-3xl font-bold mb-8 text-left">
        {t("pos.accessories.title")}
      </h1>
      <div className="flex flex-wrap justify-center md:justify-start gap-x-4 gap-y-8 xl:gap-5">
        {filteredAccessories.length > 0
          ? filteredAccessories.map((accessory: IAccessory) => (
              <AccessoryCard
                key={accessory.InvoiceCode}
                accessory={accessory}
                onSelect={handleSelectAccessory}
              />
            ))
          : emptyMessage}
      </div>
      <div className="flex justify-end mt-4">
        <Button
          label={t("pos.buttons.continue.name")}
          onClick={handleContinue}
          variant="primary"
          size="small"
        />
      </div>
    </div>
  );
};

export default Accessories;
