import React from "react";

interface ErrorIconProps {
  className?: string;
}

const ErrorIcon: React.FC<ErrorIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      fill="red">
      <circle cx="12" cy="12" r="10" fill="red" />
      <line
        x1="12"
        y1="7"
        x2="12"
        y2="12"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <circle cx="12" cy="16.5" r="1.8" fill="white" />
    </svg>
  );
};

export default ErrorIcon;
