import React from "react";
import { Controller } from "react-hook-form";
import ErrorIcon from "../../../icons/ErrorIcon";

interface InputProps {
  label: string;
  name: string;
  id: string;
  type: string;
  placeholder?: string;
  className?: string;
  error?: string;
  control: any;
  disabled?: boolean;
  value?: any;
  onAutoFill?: (value: string) => void;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      name,
      id,
      type,
      value,
      placeholder,
      className,
      error,
      control,
      disabled,
      onAutoFill,
    },
    ref
  ) => {
    return (
      <div className={`w-full ${className} relative`}>
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        <div className="relative w-full">
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <input
                id={id}
                type={type}
                {...field}
                value={value}
                ref={ref}
                placeholder={placeholder}
                disabled={disabled}
                style={{
                  border: `1px solid ${error ? "#EE3135" : "#9BA091"}`,
                  borderRadius: "9999px",
                backgroundColor: disabled ? "#F4F0EC" : "#F4F0EC",
                  color: "#000000",
                  padding: "0.5rem 1rem",
                  width: "100%",
                  paddingRight: error ? "2.5rem" : "1rem",
                  outline: "none",
                  transition: "border-color 0.3s ease",
                }}
                onFocus={(e) => {
                  if (!disabled) e.target.style.borderColor = "#CDF564";
                }}
                onBlur={(e) => {
                  if (!disabled)
                    e.target.style.borderColor = error ? "#EE3135" : "#9BA091";
                }}
                onChange={(e) => {
                  field.onChange(e);
                  if (onAutoFill) {
                    onAutoFill(e.target.value);
                  }
                }}
              />
            )}
          />
          {error && (
            <ErrorIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 text-red-500 w-5 h-5" />
          )}
        </div>
        {error && <span className="text-red-500 text-xs mt-1">{error}</span>}
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
