import React, { useEffect, useState } from "react";
import { LocationType } from "../../../interfaces/IPos";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { locationSchema } from "../schema/locationSchema";
import Input from "./Input";
import { t } from "i18next";
import SelectCountry from "../dropdown/SelectCountry";
import Button from "../buttons/Button";

interface AddLocationFormProps {
  onAddLocation: (location: LocationType) => void;
  isDeliveryAddress?: boolean;
}

const AddLocationForm: React.FC<AddLocationFormProps> = ({
  onAddLocation,
  isDeliveryAddress,
}) => {
  const methods = useForm<LocationType>({
    resolver: yupResolver(locationSchema),
    mode: "all",
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const [currentAddress, setCurrentAddress] = useState({
    street: "",
    houseNumber: "",
    addition: "",
    postalCode: "",
    city: "",
    country: "",
  });

  const street = watch("street");
  const houseNumber = watch("houseNumber");
  const addition = watch("addition");
  const postalCode = watch("postalCode");
  const city = watch("city");
  const country = watch("country");
  const locationName = watch("locationName");

  const onSubmit = (data: any) => {
    onAddLocation({ ...data, id: Date.now(), isSelected: true });
  };

  useEffect(() => {
    if (street && city) {
      setValue("locationName", `${street}, ${city}`, { shouldValidate: true });
    }
  }, [street, city, setValue]);

  useEffect(() => {
    setCurrentAddress({
      street,
      houseNumber,
      addition,
      postalCode,
      city,
      country,
    });
  }, [street, houseNumber, addition, postalCode, city, country]);

  return (
    <div className="bg-white">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="flex gap-8">
            <Input
              control={methods.control}
              label={t("pos.location.placeholder.street")}
              placeholder={t("pos.location.placeholder.street") || ""}
              {...register("street")}
              id="street"
              type="text"
              className="w-1/2"
              error={errors.street?.message as string | undefined}
            />
            <Input
              control={methods.control}
              label={t("pos.location.placeholder.house")}
              placeholder={t("pos.location.placeholder.house") || ""}
              {...register("houseNumber")}
              id="houseNumber"
              type="text"
              className="w-1/2"
              error={errors.houseNumber?.message as string | undefined}
            />
          </div>
          <Input
            control={methods.control}
            label={t("pos.location.placeholder.addition")}
            placeholder={t("pos.location.placeholder.addition") || ""}
            {...register("addition")}
            id="addition"
            type="text"
            className="w-1/2"
            error={errors.addition?.message as string | undefined}
          />
          <div className="flex gap-8">
            <Input
              control={methods.control}
              label={t("pos.location.placeholder.post")}
              placeholder={t("pos.location.placeholder.post") || ""}
              {...register("postalCode")}
              id="postalCode"
              type="text"
              className="w-1/2"
              error={errors.postalCode?.message as string | undefined}
            />
            <Input
              control={methods.control}
              label={t("pos.location.placeholder.city")}
              placeholder={t("pos.location.placeholder.city") || ""}
              {...register("city")}
              id="city"
              type="text"
              className="w-1/2"
              error={errors.city?.message as string | undefined}
            />
          </div>
          <SelectCountry
            onCountryChange={(country) => setValue("country", country)}
          />
          {!isDeliveryAddress && (
            <Input
              control={methods.control}
              label={t("pos.location.placeholder.name")}
              placeholder={t("pos.location.placeholder.name") || ""}
              {...register("locationName")}
              id="locationName"
              type="text"
              className="w-1/2"
              error={
                locationName !== `${street}, ${city}`
                  ? (errors.locationName?.message as string | undefined)
                  : undefined
              }
            />
          )}
          <div className="flex justify-end">
            <Button
              label={t("pos.buttons.finish.save")}
              variant="secondary"
              size="small"
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddLocationForm;
