import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import useCustomNavigate from "../../../components/pos/hooks/useCustomNavigate";
import { PosContext } from "../../../context/PosContext";
import { LocationType } from "../../../interfaces/IPos";
import MapView from "../../../components/pos/ui/MapView";
import LocationModal from "../../../components/pos/ui/LocationModal";
import AddLocationForm from "../../../components/pos/ui/AddLocationForm";
import Button from "../../../components/pos/buttons/Button";
import TrashIcon from "../../../icons/TrashIcon";
import PlusIcon from "../../../icons/PlusIcon";
import ConfirmDeletePopup from "../../../components/pos/ui/ConfirmDeletePopup";

const SoftPosLocation = () => {
  const { updateSteps, softPosCreationData, updateSoftPosCreationData } =
    useContext(PosContext);
  const { t } = useTranslation();

  const initialSelectedLocationId = softPosCreationData.location
    ? softPosCreationData.location.id
    : null;

  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(
    initialSelectedLocationId
  );

  const [locations, setLocations] = useState<LocationType[]>(
    softPosCreationData.location ? [softPosCreationData.location] : []
  );

  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);
  const [locationToDelete, setLocationToDelete] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const navigate = useCustomNavigate();

  const handleLocationSelect = (locationId: number) => {
    setSelectedLocationId(locationId);
  };

  const handleAddLocation = (newLocation: LocationType) => {
    const newLocationWithId = {
      ...newLocation,
      id: Math.random(),
      isSelected: true,
    };
    setLocations((prevLocations) => [...prevLocations, newLocationWithId]);
    setSelectedLocationId(newLocationWithId.id);
    updateSoftPosCreationData({ location: newLocationWithId });
    setIsModalOpen(false);
  };

  const handleDeleteIconClick = (locationId: number) => {
    setLocationToDelete(locationId);
    setIsDeletePopupOpen(true);
  };

  const handleDeleteLocation = () => {
    setLocations((prevLocations) =>
      prevLocations.filter((location) => location.id !== locationToDelete)
    );
    setIsDeletePopupOpen(false);
    setLocationToDelete(null);
  };

  const handleContinue = () => {
    if (!selectedLocationId) {
      toast.error(`${t("errors.toastError.selectLocation")}`);
      return;
    }

    const selectedLocation = locations.find(
      (loc) => loc.id === selectedLocationId
    );
    if (selectedLocation) {
      updateSoftPosCreationData({ location: selectedLocation });
      updateSteps({ softposlocation: true });
      navigate(`/pos/taptopay/configuration`);
    } else {
      toast.error(`${t("errors.toastError.noLocation")}`);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 pt-4 bg-white w-full md:max-w-3xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl">
      <h1 className="text-3xl  text-black font-bold mt-4 text-left">
        {t("terminals.softpos.location.title")}
      </h1>
      <h3 className="text-lg text-buckaroo-gray-800 font-semibold mb-8">
        {t("terminals.softpos.location.p1")}
      </h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
        <div
          className="flex flex-col w-64 ml-14 sm:ml-0 sm:w-full items-center justify-center p-6 border-2 border-dashed border-gray-300 rounded-xl shadow-sm hover:border-gray-400 cursor-pointer hover:bg-gray-50 transition duration-300"
          onClick={() => setIsModalOpen(true)}>
          <PlusIcon className="w-12 h-12 text-gray-400" />
          <span className="text-gray-500 font-semibold mt-2">
            {t("pos.location.addLocation")}
          </span>
        </div>
        {locations.map((location) => (
          <div
            key={location.id}
            className={`flex flex-col w-64 sm:w-full justify-between ml-14 sm:ml-0 p-2 border border-gray-200 rounded-xl shadow transition duration-300 transform hover:shadow-lg ${
              selectedLocationId === location.id
                ? "bg-buckaroo-gray-100"
                : "border bg-white"
            }`}>
            <MapView
              street={location.street}
              houseNumber={location.houseNumber}
              addition={location.addition}
              postalCode={location.postalCode}
              city={location.city}
              country={location.country}
              className="w-full mb-4 border flex justify-center"
              imgClassName="rounded-lg"
              zoom={17}
            />
            <div className="space-y-1 mb-2 px-3 truncate">
              <h3 className="text-xl font-semibold text-gray-800">
                {location.locationName}
              </h3>
              <p className="text-sm text-gray-600">
                {location.street}, {location.houseNumber} {location.addition}
              </p>
              <p className="text-sm text-gray-600">
                {location.postalCode}, {location.city}, {location.country}
              </p>
            </div>
            <div className="flex justify-between items-center">
              <button
                onClick={() => handleDeleteIconClick(location.id)}
                className="rounded-full text-red-500 hover:text-red-700 transition duration-300">
                <TrashIcon className="w-6 h-6" />
              </button>
              <Button
                label={
                  selectedLocationId === location.id
                    ? t("pos.buttons.select.selected")
                    : t("pos.buttons.select.name")
                }
                onClick={() => handleLocationSelect(location.id)}
                variant="secondary"
                size="xsmall"
              />
            </div>
          </div>
        ))}
      </div>
      <LocationModal
        onClose={() => setIsModalOpen(false)}
        title={`${t("terminals.softpos.location.addLocation")}`}
        showCloseIcon={true}
        isOpen={isModalOpen}>
        <AddLocationForm onAddLocation={handleAddLocation} />
      </LocationModal>
      <ConfirmDeletePopup
        isOpen={isDeletePopupOpen}
        onClose={() => setIsDeletePopupOpen(false)}
        onConfirm={handleDeleteLocation}
        removeLabel={t("pos.accessories.delete.removeBtn")}
        cancelLabel={t("pos.accessories.delete.cancelBtn")}
        warningMessage={t("pos.location.deleteConfirmation")}
      />
      <div className="flex justify-end mt-6">
        <Button
          label={t("pos.buttons.continue.name")}
          onClick={handleContinue}
          variant="primary"
          size="small"
        />
      </div>
    </div>
  );
};

export default SoftPosLocation;
