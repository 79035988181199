import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import clsx from "clsx";
import ErrorIcon from "../../icons/ErrorIcon";

interface InputProps {
  id?: string;
  name: string;
  label: string;
  type: string;
  placeholder: string;
  onFocus?: () => void;
  onChange?: (e: any) => void;
}

const InputField = ({
  id,
  name,
  label,
  type,
  placeholder,
  onFocus,
  onChange,
}: InputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="flex flex-col w-full font-medium relative">
      <span className="inline-flex items-center justify-between">
        <label htmlFor={label} className="text-sm text-gray-700">
          {label}
        </label>
      </span>
      <div className="relative w-full">
        <input
          type={type}
          data-testid={id}
          placeholder={placeholder}
          {...register(name)}
          id={id}
          className={clsx(
            "bg-white w-full rounded-full border mt-1",
            "px-4 py-2 text-sm transition duration-300 ease-in-out",
            "placeholder:text-gray-300 placeholder:text-sm",
            errors[name]
              ? "border-red-500 focus:border-red-500 pr-10"
              : "border-gray-300 focus:border-primary-500",
            "focus:outline-none focus:border-1 focus:border-primary-500"
          )}
          onFocus={onFocus}
          onChange={onChange}
        />
        {errors[name] && (
          <ErrorIcon className="absolute right-3 top-[54%] transform -translate-y-1/2 w-5 h-5" />
        )}
      </div>
      <div className="min-h-[20px]">
        <ErrorMessage
          name={name}
          render={({ message }) => (
            <div className="text-xs text-red-500">{message}</div>
          )}
        />
      </div>
    </div>
  );
};

export default InputField;
