import React from "react";
import { LocationType } from "../../../interfaces/IPos";
import HomeIcon from "../../../icons/HomeIcon";
import LocationIcon from "../../../icons/LocationIcon";
import MapIcon from "../../../icons/MapIcon";

interface DeliveryAddressCardProps {
  location: LocationType;
  isSelected?: boolean;
  onSelect?: () => void;
}

const DeliveryAddressCard: React.FC<DeliveryAddressCardProps> = ({
  location,
  isSelected,
  onSelect,
}) => {
  return (
    <div
      className={`transition-all duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 cursor-pointer ${
        isSelected
          ? "bg-buckaroo-gray-50 border-buckaroo-gray-100"
          : "bg-white border-gray-200"
      } border rounded-lg shadow-md hover:shadow-lg p-3 w-full`}
      onClick={onSelect}>
      <p className="text-gray-800 flex items-center text-sm font-medium tracking-wide leading-relaxed">
        <HomeIcon className="w-4 h-4 mr-2" />
        {location.street} {location.houseNumber}, <br />
        {location.addition}
      </p>
      <p className="text-gray-800 mb-2 flex items-center text-sm font-medium tracking-wide leading-relaxed">
        <LocationIcon className="w-4 h-4 mr-2"/>
        {location.postalCode}, {location.city}
      </p>
      <p
        className={`text-sm flex items-center font-medium tracking-wide leading-relaxed ${
          isSelected ? "text-primary-600" : "text-gray-500"
        }`}>
        <MapIcon className="w-4 h-4 mr-2"/>
        {location.country}
      </p>
    </div>
  );
};

export default DeliveryAddressCard;
