import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PosContext } from "../../context/PosContext";
import { Check } from "../../icons/Check";
import CheckIcon from "../../icons/CheckIcon";

export interface ISuccess {
  firstName: string;
  companyName: string;
}

const Success: React.FC<ISuccess> = ({ firstName, companyName }) => {
  const { t, i18n } = useTranslation();
  const { orderType } = useContext(PosContext);

  const getLinkForLanguage = (): string => {
    const language = i18n.language;
    switch (language) {
      case "en":
        return "https://www.buckaroo.eu/large-corporations/resources/integration";
      case "de":
        return "https://www.buckaroo.eu/large-corporations/resources/integration";
      case "fr":
        return "https://www.buckaroo.eu/large-corporations/resources/integration";
      case "nl":
        return "https://www.buckaroo.nl/plugins";
      default:
        return "https://www.buckaroo.nl/";
    }
  };

  const svgBackground = encodeURIComponent(`
      <svg xmlns="http://www.w3.org/2000/svg" id="uuid-7dde5a1b-7c26-453d-8eeb-6305da4274e6" data-name="Layer_1" viewBox="0 0 500 500">
        <defs>
          <style>
            .uuid-d14e57bd-57a6-4b3f-a194-dd73ce631fd3 {
              fill: #cdf564;
            }
          </style>
        </defs>
        <path class="uuid-d14e57bd-57a6-4b3f-a194-dd73ce631fd3" d="M109.53,24.13l36.63,90.1c.98,2.44,3.42,4.15,6.1,4.15h194.61c2.69,0,5.13-1.71,6.1-4.15l37.12-90.35c.98-2.44,3.42-4.15,6.1-4.15h86.68c4.64,0,7.81,4.88,6.1,9.03l-193.64,447.58c-.98,2.44-3.42,3.91-5.86,3.91h-78.63c-2.69,0-4.88-1.47-6.1-3.91L11.13,28.77c-1.95-4.4,1.22-9.03,6.1-9.03h86.68c2.2.49,4.64,1.95,5.62,4.4ZM255.55,352.56l57.63-140.4c1.71-4.4-1.47-9.03-6.1-9.03h-114.52c-4.64,0-7.81,4.64-6.1,9.03l57.14,140.4c1.95,5.37,9.77,5.37,11.96,0Z"/>
      </svg>
`);

  return (
    <div
      className="flex items-center justify-center pt-16 text-black"
      style={{
        minHeight: `calc(100vh - 70px)`,
        // backgroundImage: `url("data:image/svg+xml,${svgBackground}")`,
        backgroundPosition: "top right",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      }}>
      <div className="p-2 max-w-2xl mx-6 md:mx-auto">
        <h1 className="text-4xl font-bold mb-6 text-center">
          {t("success.title")} {firstName}!
        </h1>
        <div className="text-lg mb-16 text-center max-w-lg">
          <p> {t("success.p1")}</p>
          <p>
            {t("success.p2")} {companyName}.
          </p>
        </div>

        <h2 className="text-3xl font-bold mb-8 text-center">
          {t("success.p3")}
        </h2>
        <div className="max-w-md mx-auto">
          <ol className="relative border-l-2 border-primary-500 border-dashed">
            <li className="mb-10 ml-4 flex items-center">
              <div className="absolute w-10 h-10 bg-primary-500 rounded-full mt-1.5 -left-5 flex items-center justify-center">
                <span className="text-black text-lg font-semibold">
                  <CheckIcon className="w-6 h-6" />
                </span>
              </div>
              <h4 className="text-lg font-semibold text-primary-600 ml-5">
                {t("success.p4")}
              </h4>
            </li>

            <li className="mb-10 ml-4">
              <div className="absolute w-10 h-10 bg-black rounded-full mt-1.5 -left-5 flex items-center justify-center">
                <span className="text-primary-500 text-lg font-semibold">
                  2
                </span>
              </div>{" "}
              <p className="mb-4 ml-4">{t("success.p5")}</p>
            </li>

            {orderType?.type !== "PosOnly" && (
              <li className="mb-10 ml-4">
                <div className="absolute w-10 h-10 bg-black rounded-full mt-1.5 -left-5 flex items-center justify-center">
                  <span className="text-primary-500 text-lg font-semibold">
                    3
                  </span>
                </div>
                <p className="mb-4 ml-4">{t("success.p6")}</p>
                <a
                  href={getLinkForLanguage()}
                  rel="noopener noreferrer"
                  target="_blank">
                  <button className="ml-4 bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-4 rounded transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-opacity-50">
                    {t("success.button1")}
                  </button>
                </a>
              </li>
            )}

            <li className="ml-4">
              <div className="absolute w-10 h-10 bg-black rounded-full mt-1.5 -left-5 flex items-center justify-center">
                <span className="text-primary-500 text-lg font-semibold">
                  {orderType?.type === "PosOnly" ? 3 : 4}
                </span>
              </div>
              <p className="mb-4 ml-5">
                {orderType?.type === "PosOnly" ? "" : t("success.p7")}{" "}
                {t("success.p8")}
              </p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Success;
