import React from "react";
import Modal from "./Modal";
import WarningIcon from "../../../icons/WarningIcon";
import Button from "../buttons/Button";

interface ConfirmDeletePopupProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  removeLabel: string;
  cancelLabel: string;
  warningMessage: string;
  showIcon?: boolean;
}

const ConfirmDeletePopup: React.FC<ConfirmDeletePopupProps> = ({
  isOpen,
  onClose,
  onConfirm,
  removeLabel,
  cancelLabel,
  warningMessage,
  showIcon = true,
}) => {
  if (!isOpen) return null;

  return (
    <Modal
      onClose={onClose}
      className="max-w-lg bg-gray-100 p-6 rounded-lg shadow-md">
      <div className="flex items-center space-x-2 mb-4">
        {showIcon && <WarningIcon className="w-8 h-9 text-red-500" />}
        <span className="text-lg font-semibold text-gray-900">
          {warningMessage}
        </span>
      </div>
      <div className="flex justify-end space-x-4">
        <button
          onClick={onClose}
          className="bg-white text-buckaroo-blue-500 hover:text-gray-600 border border-gray-500 hover:bg-buckaroo-gray-300 hover:border-buckaroo-gray-400 px-4 py-2 text-sm font-semibold rounded-full transition-colors duration-300">
          {cancelLabel}
        </button>
        <button
          onClick={onConfirm}
          className="bg-white text-buckaroo-blue-500 border border-buckaroo-alert hover:bg-red-500 hover:text-white px-4 py-2 text-sm font-semibold rounded-full transition-colors duration-300">
          {removeLabel}
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmDeletePopup;
