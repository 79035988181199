import React, { useContext, useState } from "react";
import useCustomNavigate from "../../components/pos/hooks/useCustomNavigate";
import { t } from "i18next";
import { PosContext } from "../../context/PosContext";
import toast from "react-hot-toast";
import { validateTID } from "../../utils/validateTid";
import Modal from "../../components/pos/ui/Modal";
import Tooltip from "../../components/ui/Tooltip";
import BackButton from "../../components/pos/ui/BackButton";
import Button from "../../components/pos/buttons/Button";
import PlusCircleIcon from "../../icons/PlusCircleIcon";
import InfoIcon from "../../icons/InfoIcon";
import CheckIcon from "../../icons/CheckIcon";
import XIcon from "../../icons/XIcon";
import TrashIcon from "../../icons/TrashIcon";
import InfoCircleIcon from "../../icons/InfoCircleIcon";

const MigrateTerminal: React.FC = () => {
  const navigate = useCustomNavigate();
  const { updateMigrate, migrate, updateSteps } = useContext(PosContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [inputs, setInputs] = useState<string[]>(
    migrate.TIDs.length > 0 ? migrate.TIDs : [""]
  );
  const addInput = () => {
    setInputs([...inputs, ""]);
  };

  const removeInput = (index: number) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  const handleInputChange = (index: number, newValue: string) => {
    const newInputs = [...inputs];
    newInputs[index] = newValue;
    setInputs(newInputs);
  };

  const handleContinue = () => {
    const nonEmptyTIDs = inputs.filter((input) => input.trim() !== "");
    const allTIDsValid = nonEmptyTIDs.every(validateTID);
    if (!allTIDsValid || !nonEmptyTIDs?.length) {
      toast.error(`${t("errors.toastError.TID")}`);
      return;
    }
    updateMigrate({ TIDs: nonEmptyTIDs });
    updateSteps({ migrateterminal: true });
    navigate("/pos/migrate/bundle");
  };
  const tidValid = inputs.every(validateTID);
  return (
    <div className="container mx-auto px-4 py-8 pt-4 bg-white w-full md:max-w-3xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl">
      <BackButton
        label={t("pos.buttons.back.name")}
        onClick={() => navigate("/pos/migrate/location")}
      />
      <div className="flex space-x-2">
        <h1 className="text-2xl font-bold text-left relative">
          {t("pos.buttons.thirdParty.name")}
        </h1>
        <div className="-mt-2 lg:flex justify-start hidden">
          <Tooltip
            content={
              <div className="w-80 md:w-96 text-primary-800 text-base font-semibold">
                {t("pos.buttons.thirdParty.info")}
              </div>
            }
            direction="right"
            tooltipClasses="bg-primary-200 text-primary-800 shadow-lg p-3 text-lg"
            arrowClasses="bg-primary-800">
            <InfoIcon className="w-6 h-6 absolute text-primary-600 hover:text-primary-600 cursor-pointer" />
          </Tooltip>
        </div>
      </div>
      <span className="text-buckaroo-gray-800 font-semibold lg:hidden">
        {t("pos.buttons.thirdParty.info")}
      </span>
      <h3 className="text-lg text-buckaroo-gray-800 font-semibold mb-4">
        {t("pos.buttons.thirdParty.p1")}
      </h3>
      <div className="grid grid-cols-1 lg:grid-cols-5">
        <div className="col-span-1 md:col-span-2">
          {inputs.map((value, index) => (
            <div key={index} className="flex mt-4 items-center space-x-2 ">
              <div className="relative flex w-72">
                <input
                  type="text"
                  placeholder={`${t("pos.buttons.thirdParty.tid")}`}
                  value={value}
                  className="bg-buckaroo-gray-50 w-full rounded-full border border-gray-300 px-4 py-2 text-sm placeholder:text-gray-300 focus:outline-none  focus:ring-primary-500 focus:border-primary-500 hover:border-gray-400 transition duration-300 ease-in-out"
                  onChange={(e) => handleInputChange(index, e.target.value)}
                />
                <div className="absolute inset-y-0 right-2 flex items-center">
                  {value &&
                    (validateTID(value) ? (
                      <span className="text-buckaroo-green-600 bg-buckaroo-blue-500 rounded-full p-1">
                        <CheckIcon className="w-2 h-2" />
                      </span>
                    ) : (
                      <span className="text-red-500">
                        <XIcon className="w-4 h-4" />
                      </span>
                    ))}
                </div>
              </div>
              {index === inputs.length - 1 && tidValid && (
                <button
                  className="hover:text-buckaroo-green-600 rounded-lg p-2 transition-colors"
                  onClick={addInput}>
                  <PlusCircleIcon className="w-6 h-6" />
                </button>
              )}
              {index !== 0 && (
                <button
                  className=" hover:text-red-500 rounded-lg p-2 transition-colors"
                  onClick={() => removeInput(index)}>
                  <TrashIcon className="w-6 h-6" />
                </button>
              )}
            </div>
          ))}
        </div>
        <div className="flex flex-col col-span-1 md:col-span-3">
          <div className="mt-20">
            <div className="flex flex-wrap ml-20 sm:ml-0 justify-center gap-4">
              <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
                <img
                  src={require("../../images/pos/VerifoneP400.png")}
                  alt="Step 2"
                  className="w-44 md:w-40 lg:w-56 h-auto rounded-md"
                />
              </div>
              <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
                <img
                  src={require("../../images/pos/Yomani_ML.png")}
                  alt="Step 3"
                  className="w-44 md:w-40 lg:w-56 h-auto rounded-md"
                />
              </div>
              <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
                <img
                  src={require("../../images/pos/PAX_A_920.png")}
                  alt="Step 1"
                  className="w-32 -mt-7 md:w-28 xl:w-32 h-auto rounded-md"
                />
              </div>
            </div>
          </div>
          <div className="flex ml-8 sm:ml-0 mt-6 items-center justify-center sm:mt-2">
            <div className="flex space-x-2 hover:underline hover:cursor-pointer hover:text-primary-600">
              <InfoCircleIcon className="w-6 h-6" />
              <span onClick={handleOpenModal}>{t("pos.findTid.title")}</span>
            </div>
          </div>
          {isModalOpen && (
            <Modal onClose={handleCloseModal} className="max-w-lg">
              <div className="bg-white rounded-md sm:p-1 max-w-md mx-auto">
                <h2 className=" text-lg sm:text-2xl mt-6 sm:mt-0 font-bold mb-4 text-center text-primary-600">
                  {t("pos.findTid.help")}
                </h2>
                <p className="sm:mb-2 p-2 text-sm sm:text-base text-center font-semibold">
                  {t("pos.findTid.vendor")}:
                </p>
                <div className="grid grid-cols-2 p-6 gap-6 sm:gap-4">
                  {[
                    {
                      title: t("pos.findTid.ccv.title"),
                      description: t("pos.findTid.ccv.p1"),
                      link: "https://www.ccv.eu/nl/service/contact/",
                    },
                    {
                      title: t("pos.findTid.worldline.title"),
                      description: t("pos.findTid.worldline.p1"),
                      link: "https://support.worldline.com/nl-nl/home/merchants/contact.html",
                    },
                    {
                      title: t("pos.findTid.tsg.title"),
                      description: t("pos.findTid.tsg.p1"),
                      link: "https://www.tsg-solutions.com/nl/contact/",
                    },
                    {
                      title: t("pos.findTid.cm.title"),
                      description: t("pos.findTid.cm.p1"),
                      link: "https://www.cm.com/support/",
                    },
                  ].map((vendor, index) => (
                    <div
                      key={index}
                      className="bg-buckaroo-gray-50 rounded-lg p-4 flex flex-col justify-between items-center">
                      <h3 className="text-md sm:text-lg font-semibold mb-2">
                        {vendor.title}
                      </h3>
                      <p className="text-center text-sm sm:text-base">
                        {vendor.description}
                      </p>
                      <a
                        href={vendor.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-white text-buckaroo-blue-500 border border-buckaroo-blue-500 hover:bg-buckaroo-blue-500 hover:text-white px-4 py-2 text-sm font-semibold rounded-full transition-colors duration-300">
                        {t("pos.findTid.contact")}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </Modal>
          )}
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          label={t("pos.buttons.continue.name")}
          onClick={handleContinue}
          variant="primary"
          size="small"
        />
      </div>
    </div>
  );
};

export default MigrateTerminal;
