import { IBundleTransaction } from "../interfaces/IBundle";

export const belgiumBundleTransactions: IBundleTransaction[] = [
  {
    range: 50,
    pricePerMonth: 6.0,
    transactionPrice: 0.12,
    extraTransactionPrice: 0.12,
    discount: Math.round(((0.14 - 0.12) / 0.14) * 100), // 14%
  },
  {
    range: 150,
    pricePerMonth: 16.5,
    transactionPrice: 0.11,
    extraTransactionPrice: 0.11,
    discount: Math.round(((0.14 - 0.11) / 0.14) * 100), // 21%
  },
  {
    range: 250,
    pricePerMonth: 25.0,
    transactionPrice: 0.1,
    extraTransactionPrice: 0.1,
    discount: Math.round(((0.14 - 0.1) / 0.14) * 100), // 29%
  },
  {
    range: 500,
    pricePerMonth: 47.5,
    transactionPrice: 0.095,
    extraTransactionPrice: 0.095,
    discount: Math.round(((0.14 - 0.095) / 0.14) * 100), // 32%
  },
  {
    range: 1000,
    pricePerMonth: 90.0,
    transactionPrice: 0.09,
    extraTransactionPrice: 0.09,
    discount: Math.round(((0.14 - 0.09) / 0.14) * 100), // 36%
  },
  {
    range: 2000,
    pricePerMonth: 170.0,
    transactionPrice: 0.085,
    extraTransactionPrice: 0.085,
    discount: Math.round(((0.14 - 0.085) / 0.14) * 100), // 39%
  },
  {
    range: 0,
    pricePerMonth: 0.14,
    transactionPrice: 0.14,
    discount: 0,
  },
];
