import React, { useContext, useEffect, useState } from "react";
import Lease from "../../components/pos/subscription/Lease";
import Buy from "../../components/pos/subscription/Buy";
import { t } from "i18next";
import { PosContext } from "../../context/PosContext";
import { ISubscription } from "../../interfaces/ITerminal";
import useCustomNavigate from "../../components/pos/hooks/useCustomNavigate";
import { useLocation } from "react-router-dom";
import BackButton from "../../components/pos/ui/BackButton";
import Button from "../../components/pos/buttons/Button";
import InfoCircleIcon from "../../icons/InfoCircleIcon";

const Subscription: React.FC = () => {
  const { updateOrder, updateSteps, orders, activeOrderId } =
    useContext(PosContext);
  const navigate = useCustomNavigate();
  const order = orders.find((o) => o.orderId === activeOrderId);
  const subscriptions = order?.selectedTerminal?.Subscriptions || [];

  const getDefaultSubscription = () => {
    return order?.selectedSubscription &&
      subscriptions.some(
        (sub) =>
          sub.SubscriptionInvoiceCode ===
          order?.selectedSubscription?.SubscriptionInvoiceCode
      )
      ? order.selectedSubscription
      : subscriptions.find((sub) =>
          ["buy", "koop"].some((type) => sub.Type.toLowerCase().includes(type))
        ) || subscriptions[0];
  };

  const [selectedSubscription, setSelectedSubscription] = useState<
    ISubscription | undefined
  >(getDefaultSubscription());

  function useQueryParameters() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(Array.from(searchParams.entries()));
    return params;
  }

  useEffect(() => {
    const defaultSubscription = getDefaultSubscription();
    setSelectedSubscription(defaultSubscription);
    updateOrder({ ...order, selectedSubscription: defaultSubscription });
  }, [order?.selectedSubscription]);

  const handleOptionChange = (optionType: string) => {
    const newSubscription = subscriptions.find((sub) =>
      optionType === "buy"
        ? sub.Type.toLowerCase().includes("buy") ||
          sub.Type.toLowerCase().includes("koop")
        : sub.Type.toLowerCase().includes(optionType)
    );
    if (newSubscription) {
      setSelectedSubscription(newSubscription);
      updateOrder({ ...order, selectedSubscription: newSubscription });
    }
  };

  const handleContinue = () => {
    if (selectedSubscription) {
      updateOrder({ ...order, selectedSubscription });
      updateSteps({ subscription: true });
      navigate("/pos/bundle");
    }
  };

  const isOptionSelected = (type: string) => {
    return type === "buy"
      ? selectedSubscription?.Type.toLowerCase().includes("buy") ||
          selectedSubscription?.Type.toLowerCase().includes("koop")
      : selectedSubscription?.Type.toLowerCase().includes(type);
  };

  const handleBack = () => {
    navigate("/pos/terminal");
  };

  const queryParams = useQueryParameters();
  const isPartnerYellow =
    queryParams.partner ===
    "eb653c06e00ff430289f6ae7b3f899b60f2b0e02f2f082f646ce9f2c99b212ba";

  const handleRedirect = () => {
    let redirectUrl = "";

    if (isPartnerYellow) {
      redirectUrl = "https://www.buckaroo.nl/abn-amro/tarieven#postarieven";
    } else {
      const country = queryParams.country?.toUpperCase();
      const language = queryParams.lang?.toLowerCase();

      if (country === "NL") {
        if (language === "nl") {
          redirectUrl = "https://www.buckaroo.nl/tarieven/#postarieven";
        } else {
          redirectUrl = "https://www.buckaroo.eu/pricing/#postarieven";
        }
      } else if (country === "BE") {
        if (language === "nl") {
          redirectUrl = "https://www.buckaroo.be/tarieven/#postarieven";
        } else if (language === "fr") {
          redirectUrl = "https://www.buckaroo.be/fr/tarifs/#postarieven";
        } else {
          redirectUrl = "https://www.buckaroo.eu/pricing/#postarieven";
        }
      } else if (country === "DE") {
        if (language === "de") {
          redirectUrl = "https://www.buckaroo.be/de/tarife/#postarieven";
        } else {
          redirectUrl = "https://www.buckaroo.eu/pricing/#postarieven";
        }
      } else {
        redirectUrl = "https://www.buckaroo.eu/pricing/#postarieven";
      }
    }

    window.open(redirectUrl, "_blank");
  };

  return (
    <div className="container mx-auto px-4 py-8 pt-4 bg-white w-full md:max-w-3xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl">
      <BackButton label={t("pos.buttons.back.name")} onClick={handleBack} />
      <h1 className="text-3xl font-bold mb-8 text-left">
        {t("pos.subscription.heading")}
      </h1>{" "}
      <div className="rounded-lg py-2 px-3">
        <div className="flex border-2 border-buckaroo-blue-500 mb-6 w-full sm:w-1/3 md:w-1/5 rounded-lg transition-all">
          {["buy", "lease"].map((optionType, index, array) => (
            <button
              key={optionType}
              onClick={() => handleOptionChange(optionType)}
              className={`flex-1 py-1 px-4 text-lg font-medium transition-colors duration-300 ease-in-out ${
                isOptionSelected(optionType)
                  ? "bg-buckaroo-blue-500 text-white"
                  : "hover:text-buckaroo-blue-500 hover:border-buckaroo-blue-500 hover:bg-buckaroo-gray-200"
              } ${index === 0 ? "rounded-l-md" : ""} ${
                index === array.length - 1 ? "rounded-r-md" : ""
              }`}
            >
              {t(`pos.subscription.buttons.${optionType}`)}
            </button>
          ))}
        </div>
        {isOptionSelected("buy") && (
          <Buy
            selectedBuyOption={selectedSubscription}
            onSelectBuyOption={setSelectedSubscription}
          />
        )}
        {isOptionSelected("lease") && (
          <Lease
            selectedLeaseOption={selectedSubscription}
            onSelectLeaseOption={setSelectedSubscription}
          />
        )}
      </div>
      <div className="flex items-center justify-left md:mt-3 md:ml-2">
        <div
          className="flex space-x-2  hover:underline hover:cursor-pointer hover:text-primary-600"
          onClick={handleRedirect}
        >
       <InfoCircleIcon className="w-6 h-6"/>
          <span>{t("pos.subscription.info.title")}</span>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          label={t("pos.buttons.continue.name")}
          onClick={handleContinue}
          variant="primary"
          size="small"
        />
      </div>
    </div>
  );
};

export default Subscription;
