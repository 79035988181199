import React from "react";

interface GreenCheckIconProps {
  className?: string;
  width?: number;
  height?: number;
}

const GreenCheckIcon: React.FC<GreenCheckIconProps> = ({ className, width = 24, height = 24 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      className={className}
    >
      <g clipPath="url(#a)">
        <path
          fill="#B7ED44"
          d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GreenCheckIcon;
