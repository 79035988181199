import React from "react";
import { useSearchParams } from "react-router-dom";
import { bundleTransactions } from "../../data/bundleData";
import BundleCard from "../../components/pos/cards/BundleCard";
import { PosContext } from "../../context/PosContext";
import { t } from "i18next";
import Tooltip from "../../components/ui/Tooltip";
import BackButton from "../../components/pos/ui/BackButton";
import Button from "../../components/pos/buttons/Button";
import { formatDutchPrice } from "../../components/pos/utilities/formatDutchPrice";
import InfoCircleIcon from "../../icons/InfoCircleIcon";
import { belgiumBundleTransactions } from "../../data/bundleBelgiumData";

interface BundleProps {
  onContinue: () => void;
  onBack: () => void;
}

const Bundle: React.FC<BundleProps> = ({ onContinue, onBack }) => {
  const { selectedBundle, setSelectedBundle } = React.useContext(PosContext);
  const [searchParams] = useSearchParams();
  const country = searchParams.get("country");
  const isBelgium = country === "BE";
  const bundleData = isBelgium ? belgiumBundleTransactions : bundleTransactions;

  return (
    <div className="container mx-auto px-4 py-8 pt-4 bg-white w-full md:max-w-3xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl">
      <BackButton label={t("pos.buttons.back.name")} onClick={onBack} />
      <>
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-2/3 pr-4">
            <div className="flex justify-start">
              <h1 className="text-3xl font-bold relative">
                {t("pos.bundle.title")}
              </h1>
              <Tooltip
                content={
                  <div className="w-80 md:w-96 text-primary-800 font-semibold">
                    {t("pos.bundle.tooltip")}
                  </div>
                }
                direction="bottom"
                tooltipClasses="bg-primary-200 text-primary-800 shadow-lg p-3 text-lg"
                arrowClasses="bg-primary-800"
              >
                <InfoCircleIcon className="w-6 h-6 ml-1 absolute text-primary-600 hover:text-primary-700 transition-all duration-200 cursor-pointer" />
              </Tooltip>
            </div>

            <h3 className="text-buckaroo-gray-800 text-lg mb-4">
              {t("pos.bundle.p1")}
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 lg:mb-0">
              {bundleData.map((bundle, index) => (
                <BundleCard
                  key={index}
                  range={bundle.range}
                  pricePerMonth={bundle.pricePerMonth}
                  transactionPrice={bundle.transactionPrice || 0}
                  selected={selectedBundle?.range === bundle.range}
                  onSelect={() => setSelectedBundle(bundle)}
                />
              ))}
            </div>
          </div>
          <div className="border border-gray-500 sm:mx-10 mt-10"></div>
          <div className="relative flex justify-center items-start mr-18 md:mr-0 lg:border-0 lg:w-1/3 md:mt-0">
            <div className="p-8 mb-10 mt-4 md:mt-24">
              <h2 className="text-2xl font-bold mb-4">{t("pos.bundle.p3")}</h2>
              {selectedBundle && (
                <div className="text-xl">
                  <div className="mb-4 flex justify-center lg:justify-start">
                    <div className="p-2 bg-buckaroo-blue-500 rounded-full shadow-md relative min-w-[320px] md:min-w-[360px] flex items-center justify-between">
                      {selectedBundle.range !== 0 ? (
                        <p className="text-lg text-white flex items-center">
                          {t("pos.bundle.transactionPrice")}:{" "}
                          <span className="line-through text-red-500 mx-1">
                            {!isBelgium ? "€0,11" : "€0,14"}
                          </span>{" "}
                          <span className="text-buckaroo-green-500 font-bold">
                            €
                            {formatDutchPrice(
                              selectedBundle.transactionPrice || ""
                            )}
                          </span>
                        </p>
                      ) : (
                        <p className="text-lg text-center text-white">
                          {t("pos.bundle.noBundle2")}
                        </p>
                      )}
                      {selectedBundle.range !== 0 && (
                        <div className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-buckaroo-green-500 text-buckaroo-blue-500 font-bold py-2 px-1 rounded-full text-sm shadow-lg">
                          -{selectedBundle.discount}%
                        </div>
                      )}
                    </div>
                  </div>
                  {selectedBundle.range !== 0 && (
                    <p>
                      <span className="font-semibold  text-gray-600">
                        {t("pos.bundle.bundle")}:{" "}
                      </span>{" "}
                      {selectedBundle.range} {t("pos.bundle.transactions")}
                    </p>
                  )}
                  {selectedBundle.range !== 0 && (
                    <p>
                      <span className="font-semibold  text-gray-600">
                        {t("pos.bundle.price")}:{" "}
                      </span>{" "}
                      €{formatDutchPrice(selectedBundle.pricePerMonth)} /{" "}
                      {t("pos.bundle.month")}
                    </p>
                  )}
                  {selectedBundle.range === 0 && (
                    <p>
                      <span className="font-semibold  text-gray-600">
                        {t("pos.bundle.transactionFee")}:{" "}
                      </span>{" "}
                      €{formatDutchPrice(selectedBundle.pricePerMonth)}
                    </p>
                  )}
                  <p className="mb-2">
                    <span className="font-semibold  text-gray-600">
                      {t("pos.bundle.applies")}
                    </span>{" "}
                    {t("pos.bundle.applies2")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </>

      <div className="flex justify-end">
        <Button
          label={t("pos.buttons.continue.name")}
          onClick={onContinue}
          variant="primary"
          size="small"
        />
      </div>
    </div>
  );
};

export default Bundle;
